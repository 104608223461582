<template>
  <div class="modal-wrapper">
    <div class="modal-close" @click="project.visible = false">x</div>
    <div class="modal-loadingmask" v-show="!visible"></div>
    <div class="modal-container">
      <img
        v-show="visible"
        @load="isLoading"
        :src="
          require(`@/assets/images/${project.folder}/collection/${project.file}.webp`)
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["project"],
  data() {
    return {
      visible: false
    };
  },
  methods: {
    isLoading() {
      this.visible = true;
    }
  }
};
</script>

<style></style>
