<template>
  <div id="app">
    <div class="wrapper">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {}
};
</script>

<style lang="scss">
@import "@/assets/style/scss/style.scss";

.wrapper {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: url("assets/images/pattern.jpg");
  background-repeat: repeat-y;
  background-size: 100%;
}
</style>
