<template>
  <div class="header-wrapper">
    <div class="header-logo">
      <router-link to="/">
        <img src="@/assets/images/logo.png" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header"
};
</script>

<style></style>
