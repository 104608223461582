import Vue from "vue";
import VueRouter from "vue-router";
import Main from "@/views/Main.vue";
import NotFound from "@/views/NotFound.vue";
import CurrentProject from "@/views/CurrentProject.vue";
import Future from "@/views/Future.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound
  },
  {
    path: "/future",
    name: "Future",
    component: Future
  },
  {
    path: "/projects/:name",
    name: "CurrentProject",
    component: CurrentProject
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
