<template>
  <div class="notfound-wrapper">
      <div>404 - Page not found</div>
      <router-link to="/">Вернуться на главную страницу</router-link>
  </div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<style>

</style>