<template>
  <div class="main-wrapper">
    <Header />
    <div class="projects-list">
      <ProjectListCard
        v-for="(card, cardIndex) in cards"
        :card="card"
        :key="cardIndex"
      />
      <div class="projectslist-card-wrapper me">
        <div class="projectslist-card">
          <div class="projectslist-card__mask"></div>
          <div class="projectslist-card__image">
            <img src="@/assets/images/pattern_card.jpg" />
            <img class="image-logo" src="@/assets/images/logo.png" />
          </div>
          <div class="projectslist-card__about">
            <div class="projectslist-card__about-city">алексей сосновский</div>
            <div class="projectslist-card__about-little">руководитель бюро</div>
            <div class="projectslist-card__about-title">
              <span>8 921 9411961</span> / <span>34 933 158 833</span> /
              <span>info@cainterior.ru</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectListCard from "@/components/ProjectListCard";
import Header from "@/components/Header";

export default {
  name: "Main",
  components: {
    ProjectListCard,
    Header
  },
  data() {
    return {
      cards: [
        {
          index: 3,
          imgIndex: 3,
          city: "барселона",
          title: "квартира carrer de Balmes"
        },
        {
          index: 2,
          imgIndex: 2,
          city: "барселона",
          title: "входная группа жилого дома"
        },
        {
          index: 7,
          imgIndex: 7,
          city: "милан",
          title: "квартира Vicolo Giardino"
        },
        {
          index: 4,
          imgIndex: 4,
          city: "петербург",
          title: "офис строительной компании КВС"
        },
        {
          index: 6,
          imgIndex: 6,
          city: "репино",
          title: "частная резиденция"
        },
        {
          index: 12,
          imgIndex: 12,
          city: "милан",
          title: "ресторан black"
        },
        {
          index: 8,
          imgIndex: 8,
          city: "петербург",
          title: "бутик-отель Мошков"
        },
        {
          index: 9,
          imgIndex: 9,
          city: "петербург",
          title: "квартира 1 в жк de Luxe"
        },
        {
          index: 5,
          imgIndex: 5,
          city: "петербург",
          title: "квартира-студия в жк de Luxe"
        },
        {
          index: 10,
          imgIndex: 10,
          city: "петербург",
          title: "квартира 2 в жк de Luxe"
        },
        {
          index: 13,
          imgIndex: 13,
          city: "москва",
          title: "квартира в жк Пресня сити"
        },
        {
          index: 11,
          imgIndex: 11,
          city: "петербург",
          title: "лифотвой холл в жк de Luxe"
        },
        {
          index: 18,
          imgIndex: 18,
          city: "петербург",
          title: "бизнес центр мельник"
        },
        {
          index: 14,
          imgIndex: 14,
          city: "петербург",
          title: "квартира в жк Московский квартал"
        },
        {
          index: 15,
          imgIndex: 15,
          city: "москва",
          title: "пентхаус в жк ОЗ"
        },
        {
          index: 16,
          imgIndex: 16,
          city: "зеленогорск",
          title: "частный дом"
        },
        {
          index: 17,
          imgIndex: 17,
          city: "васкелово",
          title: "цоколь дома в кп Высокий стиль"
        },
        {
          index: 20,
          imgIndex: 20,
          city: "репино",
          title: "входная группа концертного зала"
        },
        {
          index: 19,
          imgIndex: 19,
          city: "петербург",
          title: "нотариальная контора"
        },
        {
          index: 22,
          imgIndex: 22,
          city: "барселона",
          title: "квартира Silvia"
        },
        {
          index: 21,
          imgIndex: 21,
          city: "лондон",
          title: "ресторан Great Embassy"
        },
        {
          index: 23,
          imgIndex: 23,
          city: "москва",
          title: "пентхаус в жк River House"
        }
      ]
    };
  }
};
</script>

<style></style>
