<template>
  <div class="future-wrapper">
    <div class="future-title">
      Подробное описание проекта скоро будет доступно.
    </div>
    <div class="future-back">
      <router-link to="/">Вернуться на главную</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Future"
};
</script>

<style></style>
