<template>
  <router-link :to="'/projects/' + card.index">
    <div class="projectslist-card-wrapper">
      <div class="projectslist-card">
        <div class="projectslist-card__mask"></div>
        <div class="projectslist-card__image">
          <img
            :src="require(`@/assets/images/${this.card.imgIndex}/preview.webp`)"
          />
        </div>
        <div class="projectslist-card__about">
          <div class="projectslist-card__about-city">{{ card.city }}</div>
          <div class="projectslist-card__about-title">{{ card.title }}</div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ProjectListCard",
  props: ["card"]
};
</script>

<style></style>
