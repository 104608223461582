<template>
  <div class="current-wrapper">
    <transition name="fade">
      <Modal :project="modalValues" v-if="modalValues.visible" />
    </transition>
    <Header />
    <div class="current-container">
      <div class="current-block">
        <div v-for="(column, index) in firstColumn" :key="column">
          <img
            @click="openModal(projectId, index + 1)"
            :src="
              require(`@/assets/images/${projectId}/collection/${index +
                1}.webp`)
            "
          />
        </div>
      </div>
      <div class="current-block">
        <div v-for="(column, index) in secondColumn" :key="column">
          <img
            @click="openModal(projectId, secondColumn + index)"
            :src="
              require(`@/assets/images/${projectId}/collection/${secondColumn +
                index}.webp`)
            "
          />
        </div>
      </div>
      <div class="current-block">
        <div v-for="(column, index) in firdColumn" :key="column">
          <img
            @click="openModal(projectId, secondColumn + firdColumn + index)"
            :src="
              require(`@/assets/images/${projectId}/collection/${secondColumn +
                firdColumn +
                index}.webp`)
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Modal from "@/components/Modal";

export default {
  name: "CurrentPage",
  components: {
    Header,
    Modal
  },
  data() {
    return {
      projectTitle: this.$route.params.name,
      imagesCount: 0,
      projectId: Number(this.$route.params.name),
      modalValues: {
        folder: 0,
        file: 0,
        visible: false
      }
    };
  },
  computed: {
    firstColumn() {
      return Math.floor(this.imagesCount / 3);
    },
    secondColumn() {
      return Math.floor((this.imagesCount - this.firstColumn) / 2);
    },
    firdColumn() {
      return this.imagesCount - (this.firstColumn + this.secondColumn);
    }
  },
  created() {
    this.imagesCountFunc(this.$route.params.name);
  },
  mounted() {
    document.body.scrollTop = 0;
  },
  methods: {
    async imagesCountFunc(index) {
      try {
        const res = await fetch(
          `https://cainterior.ru/imagesCount.php?index=${index}`,
          {
            method: "GET"
          }
        )
          .then(response => {
            return response.json();
          })
          .then(data => {
            this.imagesCount = data - 2;
          });
      } catch (e) {
        console.log(e);
      }
    },

    openModal(folder, file) {
      this.modalValues.folder = folder;
      this.modalValues.file = file;
      this.modalValues.visible = true;
    }
  }
};
</script>

<style></style>
